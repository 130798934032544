.header {
    display: flex;
    height: 65px;
    justify-content: flex-start;
    align-items: center;
    background-color: $primary;
}

.msftLogo {
    padding: 0px 30px;
    flex-shrink: 0;
    @include sm {
        display: none;
    }
}

.headerTitle {
    font-size: 1.3em;
    padding: 0 30px;
    color: $white;
    white-space: nowrap;
    @include lg {
        display: none;
    }
}

.headerRightContainer {
    width: 80%;
}

.navItemsRight {
    right: 15px;
    top: 13px;
    position: absolute;
    display: inline-flex;
}

.ms-Icon {
    &[class~='navIcon'] {
        &:hover {
            cursor: pointer;
        }
        font-size: larger;
        color: $white;
        padding-left: 15px;
        padding-top: 10px;
    }
}

.no-padding {
    padding: 0;
}

.default-nav-height {
    min-height: calc(100vh - 65px);
    @include md {
        min-height: 0; 
    };
}

.height-970 {
    min-height: 970px;
    @include md {
        min-height: 0; 
    };
}

.height-1300 {
    min-height: 1300px;
    @include md {
        min-height: 0; 
    };
}

.height-1690 {
    min-height: 1690px;
    @include md {
        min-height: 0; 
    };
}

.loadingScreen {
    margin-top: 10%;
    text-align: center;
    font-size: 2rem;
}

.hide {
    display: none;
}

.show {
    visibility: visible;
}

.ms-TextField-fieldGroup {
    background-color: $white;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 100%;
}

.ms-Dropdown-title {
    background-color: $white;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 100%;
}

#loginName {
    & [class~='ms-TooltipHost'] {
        color: $white !important;
    }
}