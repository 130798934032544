#RequestPage {
    width: 95%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 30px;
    display: block;
    @include lg {
        margin-left: 2%;
        width: 98%;
    }
}

#creatingWorkItem {
    margin-top: 20%;
    text-align: center;
    font-size: 2rem;
}

.request-category-div {
    padding-left: 5px;
    width: 33%;
}
