@mixin sm {
    @media (max-width: #{$breakpoint-sm}) {
        @content;
    }
}

@mixin md {
    @media (max-width: #{$breakpoint-md}) {
        @content;
    }
}

@mixin lg {
    @media (max-width: #{$breakpoint-lg}) {
        @content;
    }
}