.accessDenied {
    align-items: center;
    display: block;
    justify-content: space-between;
    text-align: left;
    height: 5rem;
    font-size: 26px;
    margin: 150px auto 0 auto;
    width: 60%;
    @include lg {
        font-size: 22px;
    }
    @include md {
        font-size: 18px;
    }
}

.accessDeniedHeader {
    color: $danger;
    font-size: 1.6em;
    font-weight: 700;
    text-align: center;
    .ms-Icon {
        font-size: smaller;
    }
}
