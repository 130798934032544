.request-form-table {
    width: 100%;
    margin-bottom: 35px;
    th {
        background-color: $warning-background;
    }

    tr {
        vertical-align: top;
    }
}

.section-header {
    background-color: $warning-background;
    text-align: center;
    font-weight: bold;
    padding: 7px;
    margin: 30px 0 10px 0;
}

.choiceGroup-inline-flex {
    .ms-ChoiceField {
        display: inline-block;
        margin-right: 10px;
    }
}

.alert {
    color: $danger;
}