.message {
    align-items: center;
    display: block;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    margin: 80px auto 0 auto;
    width: 70%;
    @include md {
        font-size: 16px;
    }

    .message-box {
        text-align: center;
        border-radius: 8px 8px 0 0;
        padding: 0 0 15px 0;

        .message-header {
            color: $white;
            border-radius: 8px;
            text-align: left;
            padding: 10px;
            margin-bottom: 15px;
        }

        .message-box-content {
            padding: 0 25px;
        }
    }

    .error-details {
        padding: 0 50px;
    }
}

.success {
    background-color: $success;
}

.success-background {
    background-color: $success-background;
}

.severe-warning {
    background-color: $severe-warning;
}

.severe-warning-background {
    background-color: $severe-warning-background;
}
