.attachments-label {
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto,
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: $secondary;
    box-sizing: border-box;
    box-shadow: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px;
    overflow-wrap: break-word;
}

.dropZone {
    clear: right;
    height: 8rem;
    background-color: $warning-background;
    border: 2px dashed $secondary;
    display: block;
    margin-top: 7px;
    justify-content: center;
    label {
        cursor: inherit;
    }
    text-align: center;
    overflow: auto;
    .list-group {
        padding-inline-start: 0px;
        width: 100%;
    }
}

.attachments {
    text-align: right;
}

.successFileList {
    width: 100%;
    list-style-type: none;
    background-color: $white;
}

.dropZone-label {
    color: $secondary;
    line-height: 7rem;
    height: 7rem;
    font-size: 16px;
}